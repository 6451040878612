import logo from './logo.jpg'

export default function Navigation () {
    return (
        <nav className="navbar fixed-top">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="SPEEDLINE LIMITED"/>
                </a>
                <div className="nav-item">
                    <a href="https://wa.me/+254701207207?text=Hi"><i className='fa-brands fa-square-whatsapp fa-3x'/></a>
                </div>
                <div className="nav-item">
                    <a href="https://www.facebook.com/share/H9btd2D8BPUCDXE2/?mibextid=qi2Omg"><i className='fa-brands fa-facebook fa-3x'/></a>
                </div>
                <div className="nav-item">
                    <a href="https://www.instagram.com/invites/contact/?igsh=9mnb4kkrhvjn&utm_content=2h0aegt"><i className='fa-brands fa-instagram fa-3x'/></a>
                </div>
                <div className="nav-item">
                    <a href="https://www.tiktok.com/@amaronpitstopnairobi?_t=8n5gGFYLk2v&_r=1"><i className='fa-brands fa-tiktok fa-3x'/></a>
                </div>
                <div className="nav-item">
                    <a href="https://x.com/Amaron_Kenya?t=hTit-l467SIpeunzP_LE4Q&s=09"><i className='fa-brands fa-square-x-twitter fa-3x'/></a>
                </div>
                <div className="nav-item">
                    {/*Get in touch <a className="nav-link call-link active btn btn-success btn-lg" aria-current="page" href="tel:+254701207207">0701 207 207</a>*/}
                    <a href="tel:+254701207207" title='Get in touch: 0701 207 207'><i className="fa fa-square-phone fa-3x"/>{/*<span className='call-num'>0701 207 207</span>*/}</a>
                </div>
            </div>
        </nav>
    )
}