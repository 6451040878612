// import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import Filters from "./Filters";
import Image from "./Image";
import {FetchItems} from "./data";
import {useParams} from "react-router";


function Catalogue() {
    // let Images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    let [Images, setImages] = useState([]),
        [category, setCategory] = useState("Battery"),
        [brand, setBrand] = useState(''),
        [type, setType] = useState(''),
        [cl, setCl] = useState(''),
        ImageRows = [],
        Container = []
    useEffect(function () {
        FetchItems().then(data => {
            setImages(data)
        })
    }, [])
    Images
        .filter(item => item.category === category)
        .filter(item => item.brand.startsWith(brand))
        .filter(item => item.type.startsWith(type))
        .filter(item => item.cl.startsWith(cl))
        .forEach((item, index) => {
            if (Container.length === 3) {
                ImageRows.push(Container)
                Container = []
            }
            Container.push(item)
        })
    if (Container.length > 0) {
        if (Container.length === 1) {
            Container.push(null)
            Container.push(null)
        }
        if (Container.length === 2) {
            Container.push(null)
        }
        ImageRows.push(Container)
    }

    
    
    function filterCategory(category) {
        setCategory(category)
        setCl('')
        setBrand('')
        setType('')
    }
    
    function filterClass(item_cl) {
        setCl(item_cl)
        setBrand('')
        setType('')
    }

    function filterBrand(item_brand) {
        setBrand(item_brand)
        setType('')
        if (item_brand === brand) {
            setCl('')
        }
    }

    function filterType(item_type) {
        setType(item_type)
        if (item_type === type) {
            setBrand('')
            setCl('')
        }
    }
    // function filterVehicle(item_type) {
    //     setType(item_type)
    //     setBrand('')
    // }

    
    function removeLR(string) {
        if (string.endsWith('L') || string.endsWith('R')) {
            return string.slice(0, -1)
        }
        return string
    }
    
    function listOfCategories() {
        return new Set(Images.map(image => image.category).sort())
    }
    
    function listOfClass() {
        return new Set(Images
            .filter(image => image.category === category)
            .map(image => image.cl ? image.cl.trim() : null).sort())
    }
    
    function listOfBrands() {
        return new Set(Images
            .filter(image => image.category === category)
            .filter(image => image.cl.startsWith(cl))
            .map(image => image.brand))
    }

    function listOfTypes() {
        return new Set(Images
            .filter(image => image.category === category)
            .filter(image => image.brand.startsWith(brand))
            .filter(image => image.cl.startsWith(cl))
            .map(image => image.type ? removeLR(image.type.trim()) : null).sort())
    }



    return (<div className="container back-black page-body">
            <div className="row">
                <div className="col-3 filters-list">
                    {/*<FiltersVehicle filterList={filterVehicle} filter_heading={'Vehicle'}/>*/}
                    <Filters filterList={filterCategory} filter_heading={`Item Categories`} list={listOfCategories()} selected={category} removeAll={true}/>
                    <Filters filterList={filterClass} filter_heading={`${category} Classes`} list={listOfClass()} selected={cl}/>
                    <Filters filterList={filterBrand} filter_heading={`${category} Brands`} list={listOfBrands()} selected={brand}/>
                    <Filters filterList={filterType} filter_heading={`${category} ${category == 'Tyre' ? `Sizes` : `Types`}`} list={listOfTypes()} selected={type}/>
                </div>
                <div className="col-9 catalogue">
                    <TopFilter cl={cl} brand={brand} type={type} setCl={setCl} setBrand={setBrand} setType={setType}/>
                    <CatalogueItem/>
                    <div className="text-center">
                        {
                            ImageRows.length ? ImageRows.map((Row, Index) => {
                                    return <div className="row cat-row" key={Index}>
                                        {
                                            Row.map((item, index) => {
                                                return <Image key={index} item={item}/>
                                            })
                                        }
                                    </div>
                                }) :
                                <div className='row no-items'>
                                    <div className="col">
                                        No items to display that fit the filter criteria. {(cl || brand || type) ? <><br/>Reset filters to view all items</> : ''}
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

function TopFilter({cl, brand, type, setBrand, setCl, setType}) {
    return <div className='filters-applied'>
        {cl ? <div onClick={e => setCl('')} className='filter-app-item'>Class: {cl || 'All Classes'}<i className='close fa-regular fa-circle-xmark'/></div> : ''}
        {brand ? <div onClick={e => setBrand('')} className='filter-app-item'>Brand: {brand || 'All Brands'}<i className='close fa-regular fa-circle-xmark'/></div> : ''}
        {type ? <div onClick={e => setType('')} className='filter-app-item'>Type: {type || 'All Types'}<i className='close fa-regular fa-circle-xmark'/></div> : ''}
    </div>
}

export function CatalogueItem(){
    let params = useParams()
    return Object.keys(params).length ? <div>This is a catalogue item {JSON.stringify(params)}</div> : null
}

export default Catalogue;
