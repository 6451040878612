import {backend_url} from "./settings";
import amaron from './amaron.jpg'


let options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "KSH"
};

export default function Image({item}) {
    let image_link = (item && item.image) ? `${backend_url}/api/v1/static${item.image}` : amaron

    return item ? <div className="col-4 catalogue-item">
        <div className="card">
            <div className="whatsapp">
                <a target='_blank' rel="noreferrer"  href={`https://wa.me/+254701207207?text=Hi, I would like to enquire about ${item.d} selling at ${item.sp.toLocaleString("en-US", options)}`}><i className="fa-brands fa-square-whatsapp fa-2x"/></a>
            </div>
            <div className="favourite">
                <i className="fa fa-heart"/>
            </div>
            <img src={image_link} className="card-img-top catalogue-image" alt="..."/>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h5 className="card-title">{item.d}</h5>
                        <p className="card-text">{item.sp.toLocaleString("en-US", options)}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 buy-button">
                        <a href="/#" className="btn btn-success buy-button"><i className='fa fa-cart-plus'/></a>
                    </div>
                    <div className="col-6 buy-button">
                        <a href="/#" className="btn btn-success buy-button">Buy</a>
                    </div>
                </div>
            </div>
        </div>
    </div> : <div className='col'/>
}
