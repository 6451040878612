import './App.css';
import Navigation from "./Navigation";
import Catalogue, {CatalogueItem} from "./Catalogue";
import {RouterProvider} from "react-router";
import {createBrowserRouter} from "react-router-dom";

function Footer() {
    return (
        <footer>
            <p>Branch Locations:</p>
            <ul>
                <li>Quiver - Thika Road</li>
                <li>Hamza - Jogoo Road</li>
                <li>Buruburu</li>
            </ul>
            <p>Telephone: 0720207207</p>
        </footer>
    );
}


const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <>
                <Navigation/>
                <Catalogue/>
            </>
        ),
    },
    {
        path: "catalogue",
        element: <Catalogue/>,
        children: [
            {
                path: ":item_id",
                element: (
                    <CatalogueItem/>
                ),
                // loader: ({params}) => {
                //     params.item_id;
                // }
            }
        ]
    },
]);

function App() {
    return (
        <div className="App">
            <RouterProvider router={router}/>
        </div>
    );
}


export default App;
